class StringService {
  static title(value) {
    if (!value) {
      return ""
    }

    value = (value + "").toLowerCase()
    value = value
      .split(" ")
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ")

    return value
      .split("-")
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join("-")
  }

  static ucfirst(value) {
    if (!value) {
      return ""
    }

    value = value + ""
    return value[0].toUpperCase() + value.substring(1)
  }

  static formatNumber(value) {
    return (!value ? 0 : String(value).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 "))
  }
}

export default StringService