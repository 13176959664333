<template>
  <div>
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      style="display: none;">
      <symbol id="wave">
        <path d="M420,20c21.5-0.4,38.8-2.5,51.1-4.5c13.4-2.2,26.5-5.2,27.3-5.4C514,6.5,518,4.7,528.5,2.7c7.1-1.3,17.9-2.8,31.5-2.7c0,0,0,0,0,0v20H420z"></path>
        <path d="M420,20c-21.5-0.4-38.8-2.5-51.1-4.5c-13.4-2.2-26.5-5.2-27.3-5.4C326,6.5,322,4.7,311.5,2.7C304.3,1.4,293.6-0.1,280,0c0,0,0,0,0,0v20H420z"></path>
        <path d="M140,20c21.5-0.4,38.8-2.5,51.1-4.5c13.4-2.2,26.5-5.2,27.3-5.4C234,6.5,238,4.7,248.5,2.7c7.1-1.3,17.9-2.8,31.5-2.7c0,0,0,0,0,0v20H140z"></path>
        <path d="M140,20c-21.5-0.4-38.8-2.5-51.1-4.5c-13.4-2.2-26.5-5.2-27.3-5.4C46,6.5,42,4.7,31.5,2.7C24.3,1.4,13.6-0.1,0,0c0,0,0,0,0,0l0,20H140z"></path>
      </symbol>
    </svg>

    <div class="relative bg-slate-700 overflow-hidden rounded-full w-48 h-48 border-slate-700 border-4">
      <div class="absolute inset-0 flex items-center justify-center text-white z-20 text-xl">
        <strong>{{ percent }} %</strong>
      </div>
      <div
        class="water absolute inset-0 z-10 transition-all ease-linear duration-300 bg-blue-500"
        :style="{ 'transform': `translate(0, ${100 - percent}%)` }">
        <svg
          viewBox="0 0 560 20"
          class="absolute w-[200%] bottom-full right-0 animate-[wave-back_1.4s_linear_infinite] fill-blue-300">
          <use xlink:href="#wave"></use>
        </svg>
        <svg
          viewBox="0 0 560 20"
          class="absolute w-[200%] bottom-full left-0 -mb-px animate-[wave-front_0.7s_linear_infinite] fill-blue-500">
          <use xlink:href="#wave"></use>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loader",
  data() {
    return { percent: 0 }
  },
  computed: {
    cssVars() {
      return { "--percent": this.percent }
    }
  },
  created() {
    let interval = setInterval(() => {
      this.percent++
      if (this.percent === 100) {
        this.$emit("finish")
        clearInterval(interval)
      }
    }, 15)
  }
}
</script>

<style lang="scss">
@keyframes explode {
  100% {
    transform: scale(500%);
  }
}

@keyframes wave-front {
  100% {
    transform: translate(-50%, 0);
  }
}

@keyframes wave-back {
  100% {
    transform: translate(50%, 0);
  }
}
</style>