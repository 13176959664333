<template>
  <button
    type="submit"
    :disabled="disabled || loading"
    ref="button"
    @click="handleClick"
    class="
      inline-flex justify-center items-center border border-transparent shadow-sm font-medium text-white bg-indigo-600
      transition-colors duration-300
      hover:bg-indigo-500
      focus:bg-indigo-700 focus:outline-none
      disabled:bg-gray-300 disabled:text-gray-400 disabled:cursor-not-allowed"
    :class="classes">
    <span v-if="label">{{ label }}</span>
    <component
      :is="icon"
      v-if="icon" />
    <span
      v-if="emoji"
      class="ml-4">{{ emoji }}</span>
    <loading
      v-if="loading"
      :size="18"
      class="animate-spin ml-2" />
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    label: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: "medium"
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null
    },
    emoji: {
      type: String,
      default: null
    },
    shape: {
      type: String,
      default: "rounded"
    }
  },
  computed: {
    shapeClasses() {
      const SHAPES_MAP = {
        rounded: {
          medium: "py-2 px-4 rounded-md",
          large: "uppercase text-2xl py-3 px-7 rounded-md font-bold tracking-wider"
        },
        circle: {
          small: "p-1 rounded-full",
          medium: "p-2 rounded-full"
        }
      }

      const shape = this.shape in SHAPES_MAP ? this.shape : "rounded"
      const size = this.size in SHAPES_MAP[shape] ? this.size : "medium"
      return SHAPES_MAP[shape][size]
    },
    classes() {
      return [
        this.shapeClasses
      ]
    }
  },
  methods: {
    handleClick() {
      this.$refs.button.blur()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>