<template>
  <img
    class="surfer fixed"
    :class="{'active': isActive}"
    :style="cssVars"
    :src="surfer" />
</template>

<script>
import surfer from "@/assets/surfer.svg"

export default {
  name: "AnimatedSurfer",
  props: {
    areaWidth: {
      type: Number,
      required: true
    },
    areaHeight: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      surfer,
      width: 122,
      height: 100,
      startPosition: [-122, 0],
      endPosition: [0, 0],
      isActive: false
    }
  },
  created() {
    setTimeout(() => {
      this.start(true)
    }, this.getRandomNumberBetween(5000, 10000))
  },
  computed: {
    cssVars() {
      return {
        "--width": this.width + "px",
        "--height": this.height + "px",
        "--start-x": this.startPosition[0] + "px",
        "--start-y": this.startPosition[1] + "px",
        "--end-x": this.endPosition[0] + "px",
        "--end-y": this.endPosition[1] + "px"
      }
    }
  },
  methods: {
    start(leftToRight) {
      let startX = 0
      let endX = 0
      let startY = this.getRandomNumberBetween(100, this.areaHeight - this.height - 180)
      let endY =  startY + 180

      if (leftToRight) {
        startX = -this.width
        endX = this.areaWidth
      } else {
        startX = this.areaWidth
        endX = -this.width
      }

      this.startPosition = [startX, startY]
      this.endPosition = [endX, endY]
      this.isActive = true

      setTimeout(() => {
        this.isActive = false
      }, 2000)

      setTimeout(() => {
        this.start(!leftToRight)
      }, this.getRandomNumberBetween(7000, 12000))
    },
    getRandomNumberBetween(min, max) {
      return Math.random() * (max - min) + min
    },
  }
}
</script>

<style lang="scss" scoped>
@keyframes move {
  0% {
    top: var(--start-y);
    left: var(--start-x);
  }

  100% {
    top: var(--end-y);
    left: var(--end-x);
  }
}

.surfer {
  width: var(--width);
  height: var(--height);
  top: var(--start-y);
  left: var(--start-x);

  &.active {
    animation: 2s ease-in move;
  }
}
</style>