<template>
  <div>
    <Calendar :max-visible-days="maxVisibleDays" />
    <AnimatedSurfer
      :area-width="windowWidth"
      :area-height="windowHeight" />
  </div>
</template>

<script>
import AnimatedSurfer from "@/components/AnimatedSurfer.vue"
import Calendar from "@/components/calendar/Calendar.vue"
import useWindowDimensions from "@/composables/useWindowDimensions.js"

export default {
  components: {
    AnimatedSurfer,
    Calendar
  },
  name: "Layout",
  setup() {
    const { width, height } = useWindowDimensions()
    return {
      windowWidth: width,
      windowHeight: height
    }
  },
  computed: {
    maxVisibleDays() {
      if (this.windowWidth >= 1200) {
        return 7
      }
      if (this.windowWidth >= 950) {
        return 5
      }
      if (this.windowWidth >= 650) {
        return 3
      }
      if (this.windowWidth >= 480) {
        return 2
      }
      return 1
    }
  }
}
</script>