<template>
  <div
    class="pb-8 min-h-screen bg-sky-50 h-full flex justify-center items-center"
    v-if="!isInit">
    <Loader @finish="isInit = true"/>
  </div>
  <div
    class="pb-8 min-h-screen bg-sky-50 h-full flex justify-center"
    v-else>
    <div class="fixed flex flex-row pt-8 pb-4 z-10 bg-sky-50">
      <div
        class="flex flex-col px-4 w-36 text-center"
        :class="{ 'text-indigo-700 font-bold': day.isSame($moment(), 'day') }"
        :key="index"
        v-for="(day, index) in days">
        <strong>{{ day.format("dddd") }}</strong>
        <span>{{ day.format("D MMM") }}</span>
      </div>
      <Button
        class="absolute -left-5 top-9"
        icon="chevron-left"
        rounded
        shape="circle"
        size="small"
        @click="previous" />

      <Button
        class="absolute -right-5 top-9"
        icon="chevron-right"
        shape="circle"
        size="small"
        @click="next" />
    </div>

    <div class="flex flex-row mt-28 pt-2">
      <div
        class="px-4 w-36"
        :key="index"
        v-for="(day, index) in slots">
        <Slot
          class="mb-2"
          :key="hour.hour"
          v-for="hour in day.hours"
          :hour="hour.hour"
          :total="hour.total"
          :is-loading="isLoading"
          :available="hour.available"
          :booked="hour.busy" />
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/buttons/Button.vue"
import coreApi from "@/providers/core-api"
import Loader from "@/components/Loader.vue"
import Slot from "@/components/calendar/Slot.vue"

export default {
  name: "Calendar",
  components: {
    Button,
    Loader,
    Slot
  },
  props: {
    maxVisibleDays: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      isInit: false,
      currentDay: null,
      slots: []
    }
  },
  watch: {
    maxVisibleDays: {
      immediate: true,
      handler(value) {
        let day = this.$moment().startOf("day")
        this.currentDay = (value === 7 ? day.startOf("week") : day).format("YYYY-MM-DD")
        this.loadSlots(this.currentDay, value)
      }
    }
  },
  computed: {
    days() {
      let current = this.$moment(this.currentDay)
      return [...Array(this.maxVisibleDays).keys()].map(x => {
        return current.clone().add(x, "day")
      })
    }
  },
  methods: {
    loadSlots(startDate, days) {
      const payload = {
        start_date: startDate,
        days: days
      }

      this.isLoading = true
      coreApi.slots
        .getSlots(payload)
        .then(slots => {
          this.slots = slots
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    next() {
      this.currentDay = this.$moment(this.currentDay).add(this.maxVisibleDays, "day").format("YYYY-MM-DD")
      this.loadSlots(this.currentDay, this.maxVisibleDays)
    },
    previous() {
      this.currentDay = this.$moment(this.currentDay).subtract(this.maxVisibleDays, "day").format("YYYY-MM-DD")
      this.loadSlots(this.currentDay, this.maxVisibleDays)
    }
  }
}
</script>